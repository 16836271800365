import { Controller } from '@hotwired/stimulus';
export default class extends Controller {

    connect() {

    }

    individual(e) {
        e.preventDefault()
        const form = document.querySelector('#gabarre-individual')
        form?.classList.toggle('hide')
        form.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
    }

    group(e) {
        e.preventDefault()
        document.querySelector('#gabarre-individual').classList.add('hide')
    }

}