import '@interactjs/auto-start'
import '@interactjs/actions/drag'
import '@interactjs/actions/resize'
import '@interactjs/modifiers'
import '@interactjs/dev-tools'
import interact from '@interactjs/interact'

document.addEventListener('DOMContentLoaded', () => {

    const transforms = {
        left: 'rotate(-7deg) scale(1)',
        right: 'rotate(7deg) scale(1)'
    }

interact('.draggable')
    .draggable({
        // enable inertial throwing
        inertia: true,
        // keep the element within the area of it's parent
        modifiers: [
            interact.modifiers.restrictRect({
                restriction: 'parent',
                endOnly: true
            })
        ],
        // enable autoScroll
        autoScroll: true,

        listeners: {
            // call this function on every dragmove event
            move: dragMoveListener,

            // call this function on every dragend event
            end (event) {

            },
            start(ev) {
                ev.initials = 'coucou'
            }
        }
    })

    function dragMoveListener (event) {
        var target = event.target
        // keep the dragged position in the data-x/data-y attributes
        var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
        var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
        console.warn(transforms)
        // translate the element
        let initialTransform = transforms.right
        if (target.classList.contains('left')) {
            initialTransform = transforms.left
        }
        target.style.transform = initialTransform + ' translate(' + x + 'px, ' + y + 'px)'
        // update the posiion attributes
        target.setAttribute('data-x', x)
        target.setAttribute('data-y', y)
    }

// this function is used later in the resizing and gesture demos
    window.dragMoveListener = dragMoveListener
})
