import {Controller, del} from '@hotwired/stimulus';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
export default class extends Controller {

    connect() {
        const delay = this.element.dataset.delayFactor * 250
        setTimeout(() => {
            this.reveal()
        }, delay)
    }

    reveal() {
        this.element.classList.add('visible')
    }


}