import { Controller } from '@hotwired/stimulus';
import { Calendar } from '@fullcalendar/core';
import tippy from "tippy.js";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
export default class extends Controller {

    connect() {
        const map = L.map('map').setView([45.0299,0.2255], 15);

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(map);

        const marker = L.marker([45.0299,0.2255]).addTo(map);
    }

}