import { Controller } from '@hotwired/stimulus';
export default class extends Controller {

    connect() {
        this.elementsToSwitch = document.querySelectorAll( '.' + this.element.dataset.targetClass )
        this.otherSwitchers = Array.from(document.querySelectorAll('.switcher')).filter(switcher => switcher !== this.element);
        console.log(this.otherSwitchers)
    }

    switch(e) {
        e.preventDefault()
        if(!this.element.classList.contains('active')) {
            this.element.classList.add('active')

            this.otherSwitchers.forEach(switcher => {
                switcher.classList.remove('active')
            })

            this.elementsToSwitch.forEach(el => {
                el.classList.toggle('hide')
            })
        }
    }


}