import { Controller } from '@hotwired/stimulus';
export default class extends Controller {

    connect() {
        this.originalContent = this.element.innerHTML
        this.addOn = '<i class="fa-solid fa-spinner fa-spin ml-5"></i>'
        console.log(this.element)
        this.element.addEventListener('click', this.load)
    }


    load = () => {
        console.log('clicked')
        this.element.innerHTML += this.addOn

        setTimeout(() => {
            this.element.innerHTML = this.originalContent
        }, 5000)
    }

    disconnect() {
        this.element.removeEventListener('click', this.load)
        super.disconnect();
    }

}