import {Controller, del} from '@hotwired/stimulus';
import {Notyf} from "notyf";
export default class extends Controller {

    connect() {
        this.activity = this.element.dataset.activity
        this.form = document.querySelector(`#contact-form`)
        this.submitter = this.form.querySelector('.submit')
        const inputs = this.form.querySelectorAll('input, textarea')
        this.checkForm(inputs)
        this.loader = document.createElement('i')
        this.loader.classList.add('fa-solid', 'fa-rotate', 'fa-spin', 'ml-10')
        this.notyf = new Notyf({
            duration: 7000,
            position: {
                x:"right",
                y: "top"
            },
            dismissible: true,
            types: [
                {
                    type: 'success',
                    className: 'm-toast success',

                },
                {
                    type: 'error',
                    className: 'm-toast error',

                },
                {
                    type: 'warning',
                    className: 'm-toast warning',

                },
                {
                    type: 'info',
                    className: 'm-toast info',

                }
            ]
        })
    }


    checkForm(inputs) {
        inputs.forEach(input => {
            input.addEventListener('keyup', () => {
                const formData = new FormData(this.form)
                const errors = this.validateForm(formData)
                if(errors.length === 0) {
                    this.submitter.classList.remove('disabled')
                } else {
                    this.submitter.classList.add('disabled')
                }
            })
        })
    }

    validate(e) {
        e.preventDefault()
        this.hideErrors()
        const formData = new FormData(this.form)
        const errors = this.validateForm(formData)
        if(errors.length === 0) {
            this.submit(formData)
            return
        }
        this.showErrors(errors)

        // Manage errors
    }

    showErrors(errors) {
        for (let i in errors) {
            this.form.querySelector(`#error-${errors[i]}-contact`).classList.remove('hide')
        }
    }

    hideErrors() {
        const errorFields = this.form.querySelectorAll(`.errors-contact`)
        errorFields.forEach(error => {
            error.classList.add('hide')
        })
    }

    validateForm(formData) {
        const errors = []
        for (const pair of formData.entries()) {
            const name = pair[0]
            const value = pair[1]
            switch (name) {
                case 'name' :
                    if(!this.notBlanckIsValid(value) || !this.stringIsValid(value)) {
                        errors.push('name')
                    }
                    break
                case  'firstname' :
                    if(!this.notBlanckIsValid(value) || !this.stringIsValid(value)) {
                        errors.push('firstname')
                    }
                    break
                case 'phone' :
                    if(!this.notBlanckIsValid(value) || !this.phoneIsValid(value)) {
                        errors.push('phone')
                    }
                    break
                case 'email' :
                    if(!this.notBlanckIsValid(value) || !this.emailIsValid(value)) {
                        errors.push('email')
                    }
                    break
                case 'message' :
                    if(!this.notBlanckIsValid(value) || !this.stringWithNumbersIsValid(value)) {
                        errors.push('message')
                    }
                    break
            }
        }
        return errors
    }

    emptyForm() {
        this.form.reset()
        this.submitter.classList.add('disabled')
    }

    submit(formData) {
        this.element.appendChild(this.loader)
        fetch(this.form.getAttribute('action'), {
            method: this.form.getAttribute('method'),
            body: formData,
            credentials: 'same-origin'
        }).then(response => {
            if (response.status === 200) {
                return response.json()
            }
            this.element.removeChild(this.loader)
        }).then(data => {
            if(data) {
                setTimeout(() => {
                    this.element.removeChild(this.loader)
                    switch (data.data) {
                        case 'success' :
                            this.emptyForm()
                            this.notyf.success('Message envoyé')
                            break
                        case 'form_error':
                            this.notyf.error('Il y a des erreurs dans le formulaire')
                            break
                        case 'back_error':
                            this.emptyForm()
                            this.notyf.error('Une erreur est survenue, merci de réessayer ultérieurement')
                            break
                        case 'csrf':
                            this.notyf.error('Jeton de sécurité CSRF invalide. Rechargez la page et réessayez de soumettre le formulaire')
                            break
                    }
                }, 1000)
            }

        })
    }

    notBlanckIsValid(string) {
        return string !== ''
    }
    stringIsValid(string) {
        const pattern = /^(?=.*[^ ])[a-zA-ZàâäéèêëîïôöùûüÿçÀÂÄÉÈÊËÎÏÔÖÙÛÜŸÇ\s.,;?!-]+$/
        return pattern.test(string)
    }
    stringWithNumbersIsValid(string) {
        const pattern = /^(?=.*[^ ])[$€a-zA-ZàâäéèêëîïôöùûüÿçÀÂÄÉÈ@ÊËÎÏÔÖÙÛÜŸÇ\s.,;?!&'#(){}\[\]/%*-]+$/
        return pattern.test(string)
    }

    phoneIsValid(string) {
        const pattern = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/
        return pattern.test(string)
    }

    emailIsValid(string) {
        const pattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
        return pattern.test(string)
    }

    numberIsValid(string) {
        const pattern = /[0-9]{1,}/
        return pattern.test(string)
    }

}