import { Controller } from '@hotwired/stimulus';
import {Calendar} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import frLocale from "@fullcalendar/core/locales/fr";
import tippy from "tippy.js";
export default class extends Controller {

    connect() {
        this.calendarEl = this.element

        this.events = []
        const ev = JSON.parse(this.element.dataset.dates)
        console.log('evenst', ev)
        for (let i in ev) {
            this.events.push(ev[i])
        }
        this.load()
    }

    load() {

        const updateForm = (dateId, dateFr) => {
            const formContainer = document.querySelector('#reservation-form-div')
            const form = formContainer.querySelector('form')
            const dateField = form.querySelector('input[name="date"]')
            dateField.value = dateId

            const dateFrDiv = form.querySelector('#date_name')
            dateFrDiv.innerHTML = 'pour le ' + dateFr

            formContainer.classList.add('visible')

        }

        let calendar = new Calendar(this.calendarEl, {
            plugins: [ dayGridPlugin ],
            initialView: 'dayGridMonth',
            initialDate: this.element.dataset.start,
            headerToolbar: {
                left: 'title',
                center: '',
                right: 'prev,next'
            },
            lang: 'fr',
            locale: frLocale,
            events: this.events,
            color: '#000',

            eventTimeFormat: {
                hour: '2-digit',
                minute: '2-digit',
                meridiem: false,
                formatMatcher: 'basic'
            },

            eventMouseEnter: function(info) {
                const eventObj = info.event
                tippy(info.el, {
                    content: eventObj._def.extendedProps.description,
                    placement: 'top',
                    allowHTML: true,
                })
            },

            eventClick: function(info) {
                const eventObj = info.event
                console.log(eventObj)
                if(eventObj._def.extendedProps.can_reserve) {
                    updateForm(eventObj._def.publicId, eventObj._def.extendedProps.date_fr)
                }
            },
        });

        calendar.render();

    }

}