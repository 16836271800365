import { Controller } from '@hotwired/stimulus';
import { Calendar } from '@fullcalendar/core';
import tippy from "tippy.js";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
export default class extends Controller {

    connect() {
        this.calendarEl = this.element

        this.events = []
        const ev = JSON.parse(this.element.dataset.events)
        for (let i in ev) {
            this.events.push(ev[i])
        }
        this.load()
    }

    load() {
        let calendar = new Calendar(this.calendarEl, {
            plugins: [ dayGridPlugin ],
            initialView: 'dayGridMonth',
            headerToolbar: {
                left: 'title',
                center: '',
                right: 'prev,next'
            },
            locale: frLocale,
            events: this.events,
            color: '#000',
            eventClick: function(info) {
                const eventObj = info.event
                if (eventObj.url) {
                    window.location.href = eventObj.url
                }
            },
            eventMouseEnter: function(info) {
                const eventObj = info.event
                tippy(info.el, {
                    content: eventObj._def.extendedProps.description,
                    placement: 'top',
                    allowHTML: true,
                })

            },
        });
        calendar.render();
    }

}