import { Controller } from '@hotwired/stimulus';
export default class extends Controller {

    connect() {

    }

    hideTarget(e) {
        e.preventDefault()
        const target = document.querySelector(this.element.dataset.target)
        target.classList.remove('visible')
    }


}