import { Controller } from '@hotwired/stimulus';
export default class extends Controller {

    connect() {
        const notification = this.element.querySelector('.tickets-notification')
        if(notification) {
            const newNotification = notification.cloneNode(true)
            notification.remove()
            document.body.appendChild(newNotification)
            newNotification.addEventListener('click', () => {
                newNotification.remove()
            })
        }
    }

}