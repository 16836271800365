import tippy from "tippy.js";

document.addEventListener('DOMContentLoaded', () => {

    const sidenavTriggers = document.querySelectorAll('.sidewin-trigger')

    if (sidenavTriggers) {

        sidenavTriggers.forEach(trigger => {

          trigger.addEventListener('click', (e) => {
              e.preventDefault()
              const target = document.getElementById(trigger.dataset.target)
              target.classList.toggle('open')
              document.addEventListener('keyup', (e) => {
                  if (e.code === 'Escape') {
                      target.classList.remove('open')
                  }
              })
          })
        })

    }

})