import { Controller } from '@hotwired/stimulus';


export default class extends Controller {

    connect() {
        this.target = this.element.parentElement
    }

    close(e) {
        e.preventDefault()
        this.target.classList.remove('open')
    }


}