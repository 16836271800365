// assets/js/app.js
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

import '../scss/app.scss';
import 'materialize-css'
// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import tippy from "tippy.js";

window.Stimulus = Application.start()
const context = require.context("./../controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))
//If using materialize
M.AutoInit();
require('notyf/notyf.min')

require('./Materialize/sidenavs')


require ('./urlReplace')
// any CSS you import will output into a single css file (app.css in this case)
import '../scss/app.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';
require('./interact/dragging')
